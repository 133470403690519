<template>
  <div class="container">
    <headeBar :title="pageTitle" left-arrow @click-left="appBack" />
    <div class="content">
      <!-- 卡面 -->
      <div class="card-box f26 fw6">
        <!-- 芝麻进度 -->
        <div v-if="equityCardUserExtData.sesameNowCycleIndex" class="zhima-progress f24 fw4 flex">
          <p>芝麻先享进行中</p>
          <p>
            当前进度 <span style="color: #ff6e00">{{ equityCardUserExtData.sesameNowCycleIndex }}</span
            >/{{ equityCardUserExtData.sesameAllCycleIndex }}
          </p>
        </div>
        <!-- 安心进度 -->
        <div v-if="equityCardUserExtData.aliSafePayNowCycleIndex" class="zhima-progress f24 fw4 flex">
          <p>安心付进行中</p>
          <p>
            当前进度 <span style="color: #ff6e00">{{ equityCardUserExtData.aliSafePayNowCycleIndex }}</span
            >/{{ equityCardUserExtData.aliSafePayAllCycleIndex }}
          </p>
        </div>

        <div class="card-border">
          <div class="card" :style="`background-image: url(${userEquityCardVO.backgroundImgUrl})`">
            <div class="row-between-start">
              <p class="title f44" style="font-family: PuHuiTi; font-weight: normal">{{ userEquityCardVO.cardName }}</p>
            </div>
            <div class="limit f20 row-start-end">
              <div>
                <p :style="{ color: userEquityCardVO.status === 'ACTIVE' ? '#FFDE00' : '#FFFFFF' }">{{ userEquityCardVO.statusName }}</p>
              </div>
              <div v-if="userEquityCardVO.useValidityStr">
                <p>{{ userEquityCardVO.useValidityStr }}</p>
              </div>
              <div v-if="userEquityCardVO.usePlace">
                <p>{{ userEquityCardVO.usePlace }}</p>
              </div>
              <div v-if="userEquityCardVO.levelStr">
                <p>{{ userEquityCardVO.levelStr }}</p>
              </div>
              <div v-if="userEquityCardVO.giveCardStr">
                <p>{{ userEquityCardVO.giveCardStr }}</p>
              </div>
              <div v-if="userEquityCardVO.payType === 'AUTO_RENEW'">
                <p>{{ equityCardUserExtData.isAutoRenew === 1 ? '自动续费（已开通）' : '自动续费（已关闭）' }}</p>
              </div>
              <div v-if="userEquityCardVO.payType === 'ALI_PRE_ENJOY'">
                <p :class="[userEquityCardVO.status === 'ACTIVE' ? 'blue' : '']">{{ equityCardUserExtData.isAutoRenew === 1 ? '芝麻先享（已开通）' : '芝麻先享（已关闭）' }}</p>
              </div>
              <div v-if="userEquityCardVO.payType === 'ALI_SAFE_PAY'">
                <p :class="[userEquityCardVO.status === 'ACTIVE' ? 'blue' : '']">{{ equityCardUserExtData.isAutoRenew === 1 ? '安心付（已开通）' : '安心付（已关闭）' }}</p>
              </div>

            </div>
          </div>
          <div class="bottom-btn row-end-center">
            <p v-if="userEquityCardVO.status === 'WAIT_RECEIVE' || (['WAIT_ACTIVE', 'WAIT_OPEN'].includes(userEquityCardVO.status) && !userEquityCardVO.isGiveOther)" @click.stop="cardToast(userEquityCardVO, 1)" :class="['row-center-center', userEquityCardVO.status !== 'WAIT_RECEIVE' ? 'gray' : '']">
              {{ userEquityCardVO.status === 'WAIT_RECEIVE' ? '赠送中' : '赠送好友' }}
              <van-icon v-if="userEquityCardVO.status === 'WAIT_RECEIVE'" name="arrow" />
            </p>
            <p v-else-if="userEquityCardVO.isGiveOther" @click="share(userEquityCardVO.equityCardUserId)" class="row-center-center">赠送好友</p>
            <p v-if="['WAIT_ACTIVE', 'WAIT_OPEN'].includes(userEquityCardVO.status) && !userEquityCardVO.isNowActive" @click.stop="cardToast(userEquityCardVO, 2)" class="row-center-center active gray">激活使用</p>
            <p v-else-if="userEquityCardVO.isNowActive" @click="showActivate = true" class="row-center-center active">激活使用</p>
          </div>
          <span v-if="userEquityCardVO.bottomWarn" class="tips-text row-end-center">{{ userEquityCardVO.bottomWarn }}</span>
        </div>
        <div class="btn-group row-start-center">
          <div @click="jumpUseVenue()" class="check-venue row-center-center"><img src="https://img.chaolu.com.cn/ACT/icon/home.png" alt="" />可用门店</div>
          <p></p>
          <div @click="goBuy()" class="renew-btn row-center-center"><img src="https://img.chaolu.com.cn/ACT/icon/renew.png" alt="" />续费</div>
          <p v-if="equityCardUserExtData.upgrade"></p>
          <div @click="jumpUpgrade" v-if="equityCardUserExtData.upgrade" class="renew-btn row-center-center"><img src="https://img.chaolu.com.cn/ACT/icon/up.png" alt="" />升级</div>
          <p></p>
          <div class="renew-btn row-center-center">
            <van-popover v-model="showPopover" theme="dark" trigger="click" :actions="actions" placement="bottom" @select="moreActions" id="abc">
              <template #reference>
                <div class="row-center-center more-btn"><img src="https://img.chaolu.com.cn/ACT/icon/more_icon.png" alt="" />更多</div>
              </template>
            </van-popover>
          </div>
        </div>
      </div>

      <div v-if="['ACTIVE', 'SUSPEND', 'WAIT_OPEN', 'WAIT_ACTIVE', 'WAIT_RECEIVE'].includes(userEquityCardVO.status)">
        <div class="row-between-center">
          <p class="equity-date f28 fw6">权益列表</p>
        </div>
        <!-- 特殊权益处理 -->
        <div v-for="item in limitEquityList" :key="item.equityName">
          <!-- 团课 -->
          <div v-if="item.equityType === 1" class="cl-card">
            <div class="row-between-start">
              <p class="title f28 fw6">{{ item.equityName }}</p>
            </div>
            <div class="p1 f22">{{ item.equityInstructions }}</div>
            <div class="coupon-list">
              <div v-for="(vo, index) in item.vouchers" :key="index" class="coupon-item-box">
                <div v-if="vo.type === '1'" class="coupon-item" :style="!(index % 4) ? 'margin-left: 0' : ''" :class="{ couponItemGray: vo.status !== 0 }">
                  <h2><span>¥</span>{{ vo.price }}</h2>
                  <p>{{ vo.statusDescribe }}</p>
                </div>
                <div v-else class="big-coupon-item" :style="!(index % 2) ? 'margin-left: 0' : ''" :class="{ couponItemGray: vo.status !== 0 }">
                  <div>
                    <h2><span>¥</span>{{ vo.price }}</h2>
                    <p>{{ vo.type | typeName }}</p>
                  </div>
                  <h4>{{ vo.statusDescribe }}</h4>
                </div>
              </div>
            </div>
            <p @click="goMyVoucher" v-if="userEquityCardVO.status !== 'WAIT_OPEN'" style="margin-top: 2.13vw; color: #ff6e00" class="status f24 row-start-center">已发放至优惠券账户<van-icon size="10" color="#FF6E00" name="arrow" /></p>
          </div>
          <!-- 私教 -->
          <div v-if="item.equityType === 5" class="cl-card">
            <div class="row-between-start">
              <p class="title f28 fw6">{{ item.equityName }}</p>
            </div>
            <div class="p1 f22">{{ item.equityInstructions }}</div>
            <div class="per-equity">
              <h3 class="row-center-end">
                <span class="s1">¥</span>
                <span class="s2">{{ item.per.price }}</span>
                <span class="s3">私教课</span>
              </h3>
              <p class="row-center-center" v-if="userEquityCardVO.status === 'ACTIVE'">
                当前预约权益剩余 <span>{{ item.per.residueLimitCount }}</span> 次，已使用 <span>{{ item.per.useLimitCount }}</span> 次，签到结课后恢复
              </p>
              <div @click="openUseRecord" class="row-center-center" v-if="userEquityCardVO.status === 'ACTIVE'">查看预约的课程</div>
            </div>
          </div>
          <!-- 自助 -->
          <div v-if="item.isTimeLimitedDiscount === 1" class="cl-card">
            <div class="row-between-start">
              <p class="title f28 fw6">{{ item.equityName }}</p>
              <p class="t2 f22 row-center-center" @click="goUseRecord">使用记录<van-icon color="#242831" name="arrow" /></p>
            </div>
            <div class="p1 f22">{{ item.equityInstructions }}</div>
            <van-progress :percentage="((item.ticketTotalTime - item.ticketUsedTime) / item.ticketTotalTime) * 100" stroke-width="8" pivot-text="" />
            <div class="row-between-center">
              <p class="f22">剩余{{ item.ticketTotalTime - item.ticketUsedTime < 0 ? 0 : item.ticketTotalTime - item.ticketUsedTime }}分钟</p>
              <p class="f22">共{{ item.ticketTotalTime }}分钟（{{ item.equityLabel }}）</p>
            </div>
          </div>

        </div>
        <!-- 中峰段 赠团课 -->
        <div class="scroll-box">
          <div class="cl-card small flex-wrap flex-none" v-for="item in userEquityCardEquityVOList" :key="item.equityName">
            <div class="row-between-center">
              <p class="title f28 fw6">{{ item.equityName }}</p>
            </div>
            <div class="p1 f22" v-html="item.equityInstructions"></div>
          </div>
        </div>
      </div>
      <div class="row-between-center">
        <p class="equity-date f28 fw6">卡片信息</p>
      </div>
      <div class="cardInfo">
        <p>获得时间：{{ equityCardUserExtData.getTime }}</p>
        <p v-if="equityCardUserExtData.sourceStr">来源：{{ equityCardUserExtData.sourceStr }}</p>
        <p>是否可转赠：{{ equityCardUserExtData.isGiveOther ? '是' : '否' }}</p>
        <p v-if="equityCardUserExtData.validityTimeStr">生效期：{{ equityCardUserExtData.validityTimeStr }} <span v-if="equityCardUserExtData.isOrderOpen" @click="openDetail(equityCardUserExtData.byGivingEquityCardUserId)">查看主卡</span></p>
        <p v-if="equityCardUserExtData.freezeRemark">冻结说明：{{ equityCardUserExtData.freezeRemark }}</p>
      </div>

      <!-- <div v-if="warn" class="warn fw6 row-between-center">
        <img src="https://img.chaolu.com.cn/ACT/sell-membership-2022/upgrade-icon.png" alt="">
        <p class="flex-auto f24">{{ warn.message }}</p>
        <div @click="jumpUpgrade" class="btn f20 row-center-center">{{ warn.buttonDescribe }}</div>
      </div> -->
    </div>
    <!-- 规则 -->
    <van-popup v-model="ruleShow" :style="{ backgroundColor: 'transparent' }">
      <div class="popup-box col-start-center">
        <div class="content rule-box f28">
          <div class="scroll">
            <p class="title f36 fw6">{{ ruleTitle }}</p>
            {{ rule }}
          </div>
        </div>
        <van-icon name="close" size="30" color="#fff" @click="ruleShow = false" />
      </div>
    </van-popup>
    <van-popup v-model="isCloseAutoRenewShow" position="center" class="popsure">
      <div class="sure-title f36 fw6">提示</div>
      <div class="sure-content f24">{{ hasClosed ? `关闭成功，您的超鹿月卡将于${equityCardUserExtData.validityEndTime}到期。` : '是否确认关闭自动续费？关闭后将不再发起自动扣款申请。' }}</div>

      <div v-if="hasClosed" class="row-between-center">
        <div class="sure-sub flex-auto f32 fw6" @click="isCloseAutoRenewShow = false">我知道了</div>
      </div>
      <div v-else class="row-between-center">
        <div class="sure-sub f32 cancel" @click="closeAutoRenew">确定关闭</div>
        <div class="sure-sub f32 fw6" @click="isCloseAutoRenewShow = false">继续包月</div>
      </div>
    </van-popup>
    <Activate :show.sync="showActivate" :user-info="{ userId: userId, token: token }" :user-equity-card-id="userEquityCardVO.equityCardUserId" @confirm="getCardDetail" :cardType="userEquityCardVO.type"> </Activate>
    <!-- 私教权益卡使用记录 -->
    <van-popup v-model="showUseRecord" :style="{ backgroundColor: 'transparent' }" position="bottom" closeable close-icon="https://img.chaolu.com.cn/ACT/sell-membership-2022/coach/close.png">
      <div class="record-popup-box">
        <div class="record-popup-title row-center-center">使用权益预约的课程</div>
        <div class="record-list">
          <div class="record-popup-tips">
            <h3>
              权益剩余 <span>{{ recordInfo.residueLimitCount }}</span> 次，已使用 <span>{{ recordInfo.useLimitCount }}</span> 次
            </h3>
            <p>以下课程结课/签到之后将恢复权益次数，可预约其它时间的私教课程</p>
          </div>
          <div v-if="recordInfo.courses && recordInfo.courses.length">
            <div class="record-item" v-for="item in recordInfo.courses" :key="item.orderNo">
              <h3 class="row-start-center"><span>私教</span> {{ item.courseName }}</h3>
              <div class="record-item-course row-start-start">
                <img :src="item.courseImgUrl" alt="" />
                <div>
                  <p>{{ item.tearcherName }}</p>
                  <p>{{ item.courseTimeStr }}</p>
                  <p>{{ item.venueName }}</p>
                </div>
              </div>
              <div class="record-btn row-start-center">
                <span v-if="item.isCourseFinish" class="active">已结课</span>
                <span v-else>未结课</span>
                <span v-if="item.isCourseSign" class="active">已签到</span>
                <span v-else>未签到</span>
                <p v-if="item.isUseLimitEquity" class="active">{{ item.warn }}</p>
                <p v-else>{{ item.warn }}</p>
              </div>
            </div>
          </div>
          <div v-else class="record-empty col-center-center">
            <img src="https://img.chaolu.com.cn/MINI/icon/empty_icon.png" alt="" />
            <p>暂无预约的课程</p>
          </div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import fastclick from 'fastclick'
import userMixin from '@/mixin/userMixin'
import { newAppBack, initBack, gotoAppPage, goMyVoucher, goFreeClassList, gotoOrderList, appOpenWeb } from '@/lib/appMethod'
import { getFormatDate, getParam } from '@/lib/utils'
import headeBar from '@/components/app/headBar'
import { status, activityRule, FAQ, scheduleActivityRule, coachActivityRule, monthActivityRule, vipCardRule } from './const.js'
import wx from 'weixin-js-sdk'
import Activate from "@/views/month-card/components/activate.vue";
export default {
  mixins: [userMixin],
  components: {
    Activate,
    headeBar,
  },
  data() {
    return {
      limitEquityList: [], // 高峰段的
      userEquityCardEquityVOList: [], // 权益列表 (不包含 高峰段的)
      userEquityCardVO: {
        status: 'ACTIVE',
      }, // 卡详情
      equityCardUserExtData: {},
      userMembershipCardId: '',
      userInfo: {}, // 用户信息
      status,
      activityRule,
      scheduleActivityRule,
      coachActivityRule,
      monthActivityRule,
      vipCardRule,
      FAQ,
      rule: '',
      ruleTitle: '',
      ruleShow: false,
      showActivate: false,
      showPopover: false,
      isCloseAutoRenewShow: false, // 关闭包月提示
      showUseRecord: false,
      recordInfo: {
        courses: []
      },
      pageTitle: '',
      warn: undefined, // 底部升级提示

      hasClosed: false,
    }
  },
  computed: {
    actions() {
      if (this.userEquityCardVO.payType !== 'COMMON') {
        if (this.equityCardUserExtData.isAutoRenew === 1) {
          return [{ value: 2, text: '开卡记录' }, { value: 4, text: '关闭自动续费' }, { value: 1, text: '使用规则' }, { value: 3, text: '常见问题' }]
        } else {
          return [{ value: 2, text: '开卡记录' }, { value: 1, text: '使用规则' }, { value: 3, text: '常见问题' }]
        }
      }
      return [{ value: 0, text: '赠卡记录' }, { value: 1, text: '使用规则' }, { value: 2, text: '订单详情' }, { value: 3, text: '常见问题' }]
    }
  },
  filters: {
    typeName(v) {
      switch (v) {
        case '1':
          return '团课券'
        case '2':
          return '自助券'
        case '3':
          return '通用券'
        case '4':
          return 'MINI私教券'
        case '8':
          return '私教券'
        case '9':
          return '实物券'
        case '16':
          return '小班课券'
        default:
          return ''
      }
    }
  },
  async created() {
    await this.$getAllInfo()
    initBack()
    this.userMembershipCardId = getParam().userMembershipCardId
    this.getCardDetail()
  },
  mounted() {
    // 解决iOS设备 必须点击两次才触发的问题
    fastclick.attach(document.getElementById('abc'))
    document.addEventListener('visibilitychange', () => {
      if (document.visibilityState === 'visible') {
        this.getCardDetail()
      }
    })
  },
  methods: {
    openDetail(id) {
      // appOpenWeb('', `${window.location.origin}/#/month-card/my-card-detail?token=1&userId=1&userMembershipCardId=${id}`)
      this.userMembershipCardId = id
      this.getCardDetail()
    },
    openUseRecord() {
      this.$toast.loading({
        duration: 0,
        forbidClick: true,
        loadingType: "spinner",
      });
      this.$axios.post(`${this.baseURLApp}/user/equityCard/perEquityCardUseRecordSearch`, {
        userId: this.userId,
        token: this.token,
        userEquityCardId: this.userMembershipCardId
      }).then((res) => {
        this.recordInfo = res.data
        this.showUseRecord = true
      }).finally(r => {
        this.$toast.clear()
      })
    },
    appBack: newAppBack,
    getCardDetail() {
      this.$toast.loading({
        duration: 0,
        forbidClick: true,
        loadingType: "spinner",
      });
      this.$axios.post(`${this.baseURLApp}/user/equityCard/equityCardUserDetail/v2`, {
        userId: this.userId,
        token: this.token,
        userEquityCardId: this.userMembershipCardId
      }).then((res) => {
        // 处理权益项
        this.limitEquityList = []
        this.userEquityCardEquityVOList = []

        // res.data.equities[1].scheduleFree.isWarn = false
        // res.data.equities[1].scheduleFree.truantCountOfWeek = 1
        // res.data.equities[1].scheduleFree.warnTruantCount = 2

        res.data.equities.map((item) => {
          let condition = item.isTimeLimitedDiscount === 1 ||
            (item.equityType === 1 && item.vouchers !== undefined) ||
            (item.equityType === 5 && item.per !== undefined) ||
            (item.equityType === 3 && item.scheduleFree !== undefined) ||
            (item.equityType === 3 && item.scheduleEspecially !== undefined)
          condition ? this.limitEquityList.push(item) : this.userEquityCardEquityVOList.push(item)
        })

        this.userEquityCardVO = res.data.equityCardUser // 权益卡面信息
        this.equityCardUserExtData = res.data.equityCardUserExtData // 权益卡额外信息
        this.warn = res.data.warn

        this.pageTitle = this.getCardType().pageTitle
        document.title = this.getCardType().pageTitle
      }).finally(() => {
        this.$toast.clear()
      })
    },
    goBuy() {
      // vip自助卡判断
      if (this.userEquityCardVO.type === 4) {
        this.$toast('请联系门店教练选购')
        return
      }

      let path = this.getCardType().path
      this.baseJump(`/superdeer-activity/sell-membership${path}?cardCityId=${this.userEquityCardVO.cityIds[0]}`)
    },
    goUseRecord() {
      this.baseJump('/month-card/use-record-self?userMembershipCardId=' + this.userMembershipCardId)
    },
    jumpUseVenue() {
      this.baseJump(`/month-card-venue?userMembershipCardId=${this.userMembershipCardId}&location=1`)
    },
    goMyVoucher() {
      goMyVoucher('6')
    },
    baseJump(path) {
      if (this.appTypeStr === 'mini') {
        path = path.replace('?', '&')
        wx.miniProgram.navigateTo({
          url: `/pages/webView/index?webUrl=${window.location.origin}/#${path}&userId=1&token=1&cityId=1`,
        })
      } else {
        this.$router.push(path)
      }
    },
    getCardType() {
      let typeObj = {}
      switch (this.userEquityCardVO.type) {
        case 0:
          typeObj = {
            rule: this.activityRule,
            ruleTitle: '自助健身卡规则及权益说明',
            path: '',
            pageTitle: '自助健身卡',
          }
          break
        case 1:
          typeObj = {
            rule: this.scheduleActivityRule,
            ruleTitle: '团课健身卡规则及权益说明',
            path: '',
            pageTitle: '团课健身卡',
          }
          break
        case 2:
          typeObj = {
            rule: this.coachActivityRule,
            ruleTitle: '超鹿包月私教卡及同类季卡、半年卡、年卡权益和使用规则',
            path: '/coach',
            pageTitle: '超鹿包月私教',
          }
          break
        case 3:
          typeObj = {
            rule: this.monthActivityRule,
            ruleTitle: '超鹿月卡权益和使用规则',
            path: '',
            pageTitle: '超鹿月卡',
          }
          break
        case 4:
          typeObj = {
            rule: this.vipCardRule,
            ruleTitle: 'SVIP自助卡权益和使用规则',
            path: '',
            pageTitle: 'SVIP自助卡',
          }
          break
      }
      typeObj.FAQ = this.FAQ[this.userEquityCardVO.type]
      return typeObj
    },
    moreActions(v, index) {
      switch (v.value) {
        case 0:
          this.jumpRecord()
          break
        case 1:
          this.ruleShow = true
          this.rule = this.getCardType().rule
          this.ruleTitle = this.getCardType().ruleTitle
          break
        case 2:
          if (!this.equityCardUserExtData.orderNo) {
            this.$toast('当前权益卡非当前账户购买获得，无对应订单记录')
            return false
          }
          gotoAppPage(7, { payOrderNo: this.equityCardUserExtData.orderNo })
          break
        case 3:
          this.ruleShow = true
          this.rule = this.getCardType().FAQ
          this.ruleTitle = '常见问题'
          break
        case 4:
          this.isCloseAutoRenewShow = true
          break;
        case 5:
          gotoOrderList()
          break
      }
    },
    jumpRecord() {
      this.$router.push({
        path: `/give-equity-card/record?userId=${this.userId}&token=${this.token}`,
      })
    },
    // 跳转超鹿月卡免费团课页面
    jumpClassList() {
      goFreeClassList()
    },
    // 赠送卡
    share(userEquityCardId) {
      if (this.appTypeStr === 'mini') {
        wx.miniProgram.navigateTo({
          url: `/pages/webView/index?webUrl=${window.location.origin}/#/give-equity-card&userId=1&token=1&recordId=${userEquityCardId}`
        })
      } else {
        this.$router.push({
          path: '/give-equity-card',
          query: { recordId: userEquityCardId }
        })
      }
    },
    cardToast(v, type) {
      if (v.status === 'WAIT_RECEIVE' && type == 1) {
        appOpenWeb('', `${window.location.origin}/#/give-equity-card/record?userId=1&token=1`)
        return
      }

      let toast = ''
      if (v.status === 'WAIT_RECEIVE') {
        toast = '当前卡在赠送中，无法激活'
      } else if (v.isGiveCard && type === 2) {
        toast = '当前卡为附赠卡，无法激活”'
      }
      toast && this.$toast(toast)
    },
    jumpUpgrade() {
      // this.equityCardUserExtData.upgrade.type  upgrade.type    ACTIVE_UPGRADE新页面 PER_UPGRADE私教升级
      switch (this.equityCardUserExtData.upgrade.type) {
        case 'ACTIVE_UPGRADE':
          if (this.appTypeStr === 'mini') {
            wx.miniProgram.navigateTo({
              url: `/pages/webView/index?webUrl=${window.location.origin}/#/superdeer-activity/sell-membership/monthcard-upgrade&equityCardUserId=${this.userMembershipCardId}&activeId=${this.equityCardUserExtData.upgrade.activeId}`
            })
          } else {
            this.$router.push({
              path: `/superdeer-activity/sell-membership/monthcard-upgrade?equityCardUserId=${this.userMembershipCardId}&activeId=${this.equityCardUserExtData.upgrade.activeId}`
            })
          }
          break;
        default:
          if (this.appTypeStr === 'mini') {
            wx.miniProgram.navigateTo({
              url: `/pages/webView/index?webUrl=${window.location.origin}/#/superdeer-activity/sell-membership/coach-upgrade&userId=1&token=1&equityCardUserId` + this.userMembershipCardId
            })
          } else {
            this.$router.push({
              path: `/superdeer-activity/sell-membership/coach-upgrade?equityCardUserId=${this.userMembershipCardId}`
            })
          }
          break;
      }
    },
    closeAutoRenew() {
      this.$toast.loading({
        duration: 0,
        forbidClick: true,
        loadingType: "spinner",
      })
      this.$axios.post(`${this.baseURLApp}/user/equityCard/equityCardAutoRenewCancel`, {
        equityCardUserId: this.userEquityCardVO.equityCardUserId,
        userId: this.userId,
        token: this.token,
      }).then(res => {
        this.hasClosed = true
        this.getCardDetail()
      })
    }
  },
}
</script>
<style lang="less" scoped>
.record-popup-box {
  background-color: #f5f5f5;
  border-radius: 16px 16px 0 0;
  overflow: hidden;
  padding-bottom: env(safe-area-inset-bottom);

  .record-empty {
    padding-top: 16vh;

    img {
      width: 320px;
      height: 320px;
    }

    p {
      margin-top: 20px;
      font-size: 26px;
      line-height: 40px;
      color: #6c727a;
    }
  }

  .record-list {
    height: 70vh;
    overflow-y: auto;
    padding-bottom: 50px;
    box-sizing: border-box;

    .record-item {
      margin: 24px auto 0;
      width: 686px;
      background: #ffffff;
      border-radius: 16px 16px 16px 16px;
      padding: 32px 28px;
      box-sizing: border-box;

      .record-btn {
        margin-top: 32px;

        span {
          padding: 8px;
          background: #fff2f0;
          border-radius: 4px 4px 4px 4px;
          font-weight: bold;
          color: #f03c18;
          line-height: 22px;
          font-size: 22px;
          margin-right: 12px;

          &.active {
            color: #267dff;
            background: #f2f7ff;
          }
        }

        p {
          font-size: 24px;
          line-height: 24px;
          color: #242831;
          margin-right: 12px;

          &.active {
            color: #f03c18;
          }
        }
      }

      .record-item-course {
        margin-top: 32px;

        img {
          width: 208px;
          height: 192px;
          border-radius: 4px;
          object-fit: cover;
          margin-right: 28px;
        }

        & > div {
          padding: 8px 0;

          p {
            margin-bottom: 24px;
            font-size: 24px;
            color: #3c454e;
            line-height: 24px;
          }
        }
      }

      h3 {
        line-height: 28px;
        font-size: 28px;

        span {
          padding: 8px;
          line-height: 22px;
          font-size: 22px;
          color: #ffffff;
          background: #3c454e;
          border-radius: 4px 4px 4px 4px;
          margin-right: 16px;
        }
      }
    }
  }

  .record-popup-tips {
    padding: 32px 32px 8px;

    h3 {
      line-height: 28px;
      font-size: 28px;

      span {
        color: #ff6e00;
        margin: 0 4px;
      }
    }

    p {
      margin-top: 24px;
      font-size: 22px;
      color: #242831;
      line-height: 22px;
    }
  }

  .record-popup-title {
    width: 750px;
    height: 120px;
    line-height: 32px;
    background: #ffffff;
    font-weight: bold;
    font-size: 32px;
  }
}

.popsure {
  width: 590px;
  border-radius: 12px;
  padding: 50px 40px;

  .sure-title {
    color: #242831;
    text-align: center;
  }

  .sure-content {
    color: #3c454e;
    margin: 30px 0 56px;
    line-height: 1.8;
    text-align: center;
  }

  .sure-sub {
    width: 244px;
    border-radius: 8px;
    background: #ffde00;
    color: #000;
    line-height: 96px;
    text-align: center;

    &.cancel {
      background: #fff;
      line-height: 94px;
      border: 1px solid #d9d9d9;
      color: #3c454e;
    }
  }
}

.container {
  padding: 0 0 100px;
  background-color: #f5f5f5;
  min-height: 100vh;
  box-sizing: border-box;

  * {
    box-sizing: border-box;
  }
}

.content {
  .scroll-box {
    width: 686px;
    margin: 0 auto;
  }

  .user {
    .avator {
      width: 72px;
      border-radius: 50%;
    }

    .name {
      margin: 0 0 0 14px;

      p {
        width: 260px;
      }

      .status {
        margin: 8px 0 0;
      }
    }
  }

  .card-box {
    background-color: white;
    width: 750px;
    padding: 16px 32px 28px;
    .zhima-progress {
      padding: 18px 4px 20px;
      color: #242831;
    }
    .btn-group {
      width: 686px;
      height: 80px;
      background: #ffffff;
      border-radius: 12px;
      border: 1px solid #eee;

      div {
        img {
          width: 32px;
          height: 32px;
          margin-right: 6px;
        }
      }

      .check-venue {
        width: 32%;
      }

      .renew-btn {
        // width: 200px;
        flex: 1;
      }

      p {
        width: 2px;
        height: 32px;
        background: #eee;
        margin: 0 8px;
      }

      margin: 28px auto 0;
    }
  }

  .card {
    width: 100%;
    padding: 24px 28px 28px;
    background-size: cover;
    background-repeat: no-repeat;
    color: #fff;
    height: 320px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .title {
      max-width: 8em;
      margin: -8px 0 0;
      text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
      -webkit-background-clip: text;
    }

    .limit {
      margin: 36px 0 0;
      width: 100%;
      div {
        flex: none;
      }
      p {
        color: #fff;
        font-weight: bold;
        padding: 8px 12px;
        font-size: 20px;
        line-height: 20px;
        border-radius: 4px;
        background: rgba(0, 0, 0, 0.5);
        margin-right: 16px;
        &.blue {
          background: #1777ff;
        }
      }
    }
  }

  .equity-date {
    margin-top: 48px;
    color: #242831;
    padding: 0 32px;
  }

  .cl-card {
    width: 686px;
    padding: 40px 28px 40px;
    background: #ffffff;
    border-radius: 16px;
    margin: 24px auto 0;
    overflow: hidden;

    &.small {
      width: 686px;
    }

    .title {
      max-width: 470px;
    }

    .p1 {
      line-height: 34px;
      margin: 16px 0 0;
      color: #242831;
    }

    .van-progress {
      margin: 40px 0 24px;
    }

    .status {
      margin: 40px 0 0;
    }

    .van-icon {
      color: #9aa1a9;
      font-weight: 600;
      margin: 0 0 0 6px;
    }
  }
}

.popup-box {
  width: 654px;

  .title {
    text-align: center;
    margin: 0 0 28px;
    color: #242831;
  }

  .rule-box {
    width: 654px;
    max-height: 900px;
    background-color: #fff;
    padding: 60px 0;
    color: #6c727a;
    border-radius: 12px;
    box-sizing: border-box;

    .scroll {
      max-height: 800px;
      padding: 0 48px;
      box-sizing: border-box;
      overflow: auto;
      -webkit-overflow-scrolling: touch;
      white-space: pre-line;
      word-break: break-all;
    }
  }

  i {
    margin: 32px 0 0;
  }
}

.activate-btn {
  width: 144px;
  height: 64px;
  background: #ff6e00;
  border-radius: 8px;
  font-size: 24px;
  font-weight: bold;
  color: #ffffff;
}

.cardInfo {
  width: 686px;
  background: #ffffff;
  border-radius: 12px;
  padding: 32px 28px;
  margin: 24px auto 0;

  p {
    color: #242831;
    font-size: 22px;
    line-height: 34px;

    & + p {
      margin-top: 8px;
    }

    span {
      background: right center url('https://img.chaolu.com.cn/ACT/icon/arrow_back_gray.png') no-repeat;
      background-size: 22px 22px;
      padding-right: 26px;
      margin-left: 16px;
    }
  }
}

.card-border {
  width: 100%;
  box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.08);
  border-radius: 16px;
  border: 1px solid #eee;
  overflow: hidden;
  box-sizing: border-box;
}

.bottom-btn {
  padding: 0 28px;

  p {
    width: 144px;
    height: 56px;
    border-radius: 4px;
    font-size: 24px;
    font-weight: bold;
    line-height: 24px;
    border: 1px solid #6c727a;
    box-sizing: border-box;
    margin: 24px 0 24px 20px;

    &.active {
      border-color: #ff6e00;
      background: #ff6e00;
      color: #ffffff;
    }

    &.gray {
      background: #eeeeee;
      color: #9aa1a9;
      border-color: #eee;

      &.active {
        border-color: #eeeeee;
        background: #eee;
        color: #9aa1a9;
      }
    }
  }
}

.coupon-list {
  display: flex;
  flex-wrap: wrap;
  margin-top: 24px;

  .coupon-item-box {
    //margin-right: 10px;
    margin-bottom: 24px;
    //&:nth-child(4n){
    //    margin-right: 0;
    //}
  }

  .big-coupon-item {
    width: 298px;
    height: 128px;
    background: url('https://img.chaolu.com.cn/ACT/schedule-2023/coupon1.png') no-repeat center center;
    background-size: 100% 100%;
    color: #683e14;
    margin-left: 26px;
    display: flex;
    padding: 8px;
    box-sizing: border-box;
    align-items: center;

    & > div {
      width: 142px;

      h2 {
        text-align: center;
        line-height: 52px;
        font-size: 52px;
        font-family: BebasNeue;

        span {
          font-size: 36px;
          margin-right: 4px;
        }
      }

      p {
        font-size: 20px;
        line-height: 20px;
        text-align: center;
        color: #683e14;
        font-weight: 400;
      }
    }

    h4 {
      line-height: 28px;
      font-size: 26px;
      flex-grow: 1;
      text-align: center;
    }

    &.couponItemGray {
      background-image: url('https://img.chaolu.com.cn/ACT/schedule-2023/coupon1_gray.png');
      color: #fff;

      & > div {
        color: #242831;

        p {
          color: #242831;
        }
      }
    }
  }

  .coupon-item {
    width: 148px;
    height: 162px;
    background: url('https://img.chaolu.com.cn/ACT/schedule-2023/coupon.png') no-repeat center center;
    background-size: 100% 100%;
    color: #683e14;
    margin-left: 10px;

    &.couponItemGray {
      background-image: url('https://img.chaolu.com.cn/ACT/schedule-2023/coupon_gray.png');
      color: #fff;
    }

    h2 {
      padding-top: 30px;
      text-align: center;
      line-height: 64px;
      font-size: 64px;
      font-family: BebasNeue;

      span {
        font-size: 36px;
      }
    }

    p {
      font-size: 22px;
      line-height: 22px;
      font-weight: 600;
      text-align: center;
      margin-top: 26px;
    }
  }
}

/*  私教权益 */
.per-equity {
  h3 {
    margin-top: 28px;
    color: #f03c18;
    padding: 12px 0;

    .s1 {
      line-height: 26px;
      font-size: 28px;
    }

    .s2 {
      line-height: 40px;
      font-size: 56px;
      margin: 0 4px;
    }

    .s3 {
      font-size: 28px;
      line-height: 30px;
    }
  }

  p {
    line-height: 26px;
    color: #242831;
    font-size: 24px;
    margin-top: 20px;

    span {
      color: #f03c18;
      margin: 0 4px;
    }
  }

  div {
    margin-top: 48px;
    width: 630px;
    height: 72px;
    background: #ffde00;
    border-radius: 6px;
    font-weight: bold;
    font-size: 26px;
  }
}

.class-equity {
  margin-top: 48px;

  .btn {
    margin-top: 48px;
    width: 630px;
    height: 80px;
    background: #ffde00;
    border-radius: 6px;
    font-weight: bold;
    font-size: 26px;
  }

  .week-tips {
    width: 630px;
    background: #f0f4ff;
    border-radius: 8px;
    padding: 90px 20px 44px;
    position: relative;

    & + .week-tips {
      margin-top: 28px;
    }

    &.red {
      background: #fff1ed;

      h3 {
        color: #f03c18;

        i {
          margin-left: 8px;
          font-style: normal;
          width: 92px;
          height: 36px;
          border-radius: 4px;
          border: 1px solid #f03c18;
          line-height: 24px;
          font-size: 24px;
        }
      }

      & > span {
        background: rgba(240, 60, 24, 0.78);
      }
    }

    & > span {
      width: 172px;
      height: 46px;
      background: rgba(38, 125, 255, 0.78);
      border-radius: 8px 0 8px 0;
      position: absolute;
      left: 0;
      top: 0;
      opacity: 1;
      font-weight: 600;
      color: #ffffff;
      font-size: 22px;
      line-height: 22px;
    }

    h3 {
      text-align: center;
      font-size: 32px;
      line-height: 32px;
      color: #267dff;

      .s1 {
        font-size: 42px;
        line-height: 42px;
      }

      .s2 {
        font-size: 26px;
        line-height: 30px;
      }
    }

    div {
      text-align: center;
      margin-top: 24px;
      font-size: 22px;
      line-height: 34px;
      color: #242831;
    }
  }
}

.tips-text {
  text-align: right;
  padding-bottom: 24px;
  font-size: 22px;
  line-height: 22px;
  color: #f03c18;
  font-weight: 400;
  padding-right: 28px;
}

.warn {
  width: 686px;
  box-sizing: border-box;
  height: 96px;
  padding: 0 24px;
  margin: 24px auto 18px;
  background: #f0f5ff;
  border-radius: 12px 12px 12px 12px;
  border: 0.5px solid rgba(154, 161, 169, 0.7);

  img {
    width: 48px;
    margin: 0 12px 0 0;
  }

  .btn {
    padding: 0 24px;
    height: 48px;
    margin: 0 0 0 24px;
    color: #fff;
    background: #242831;
    border-radius: 4px;
  }
}
</style>
<style>
.van-popover__action {
  padding: 0 !important;
  width: 228px;
}
</style>
